import React, { Component } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { login } from "variables/constants";

const AdminNavbarLinks = (props) => {
  const { logged } = props;

  const user = React.useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem("user"))?.data?.user || null;
    } catch (error) {
      console.error("Failed to parse user data:", error);
      return null;
    }
  }, []);

  const name = user ? `${user.first_name} ${user.last_name}` : null;
  const userId = user?.id?.toString() || null;

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Nav>
        {!logged && <NavDropdown.Item href={login}>Login</NavDropdown.Item>}
        {logged && (
          <div style={{ display: "flex", marginRight: 20 }}>
            <NavDropdown.Item href={`/admin/users/${userId}/edit`}>
              {name}
            </NavDropdown.Item>
            <NavDropdown.Item
              style={{ marginLeft: 50 }}
              href={login}
              onClick={() => localStorage.clear()}
            >
              Logout
            </NavDropdown.Item>
          </div>
        )}
      </Nav>
    </div>
  );
};

export default AdminNavbarLinks;
