import React, { Component, useMemo, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import authHeader from "../../services/auth-header";

const Header = (props) => {
  const [sidebarExists, setSidebarExists] = useState(false);
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

  const mobileSidebarToggle = (e) => {
    setSidebarExists(!sidebarExists);
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
  };

  return (
    <Navbar className="navbar navbar-default" collapseOnSelect expand="lg">
      <Navbar.Brand href="#home">{props?.brandText}</Navbar.Brand>
      <Navbar.Toggle onClick={(e) => mobileSidebarToggle(e)} />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav>
          <AdminNavbarLinks logged={authHeader} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
