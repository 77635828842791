import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import Textarea from "react-validation/build/textarea";
import AuditService from "../../../services/audit.service";
import CanceledStatusService from "../../../services/purchaseCanceledStatus.service";
import FormService from "../../../services/form.service";
import ManagementService from "../../../services/management.service";
// import UserService from "../../../services/user.service";
import TransactionTypeService from "../../../services/transactionType.service";
import { Comments } from "../../Comments";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import ProgressBar from "../../ProgressBar/ProgressBar";
import Dropdown from "../../Table/Dropdown";
import { BadgeField } from "../Sells/components";
import { diffDateFormula, plateNumberBadge } from "../../../utils/Badges";
import { purchaseComment } from "../../../utils/Comments";
import { issuerEntities } from "../../../utils/Service";
import { constants, purchaseStatus } from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks";
import { SelectField } from "../../../components/Form";

const finalStatus = [
  purchaseStatus.COMPLETED.value,
  purchaseStatus.REJECTED.value,
  purchaseStatus.DISMISSED_KAVAK.value,
];

const Content = (props) => {
  const {
    data: {
      response: {
        vehicle_id,
        plate_number,
        sdp,
        status: purchase_file_status,
        issuer_entity,
        purchase_file_form: folio_in_progress_forms,
        // loss_request,
        // person_in_charge,
        // person_in_charge_id,
        final_payment_date,
        restore_status,
        diff_days,
        transaction_type_id,
        pledge,
        canceled_status_id,
        enterprise_signature,
      },
    },
    handleUpdate,
    handleClose,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    vehicle_id,
    plate_number,
    purchase_file_status,
    // person_in_charge,
    // person_in_charge_id,
    final_payment_date,
    // transaction_type_id,
    // issuer_entity_id: issuer_entity.id,
    folio_in_progress_forms:
      folio_in_progress_forms.length > 0
        ? folio_in_progress_forms.map((f) => ({
            code: f.form_code,
            has_folio_in_progress: f.has_folio_in_progress,
            description: f.form.description,
          }))
        : [],
  };

  const [completed, setCompleted] = useState(0);
  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [customConfirmText, setCustomConfirmText] = useState("");
  const [canceledStatus, setCanceledStatus] = useState([]);
  const [disabledComment, setDisabledComment] = useState(true);

  const [transactionType, setTransactionType] = useState("");
  const [cancelStatus, setCancelStatus] = useState("");
  const [resetCommentForm, setResetCommentForm] = useState(0);

  // const [selectedIssuer, setSelectedIssuer] = useState([
  //   {
  //     value: issuer_entity.id,
  //     text: issuer_entity.description,
  //   },
  // ]);

  // const onSelect = (selectedList, selectedItem) => {
  //   setSelectedIssuer([selectedItem]);
  //   onSimpleFieldChange("issuer_entity_id", selectedItem.value);
  // };

  // const onRemove = () => {
  //   onSimpleFieldChange("issuer_entity_id", null);
  // };

  const {
    handlePreConfirmationPurchaseModal,
    confirmationModal,
    handleConfirmationModal,
  } = useConfirmationAlert();

  // const [employeeOptions, setEmployeeOptions] = useState([]);

  const confirmationContent = ({
    transactionType,
    ranking,
    pledge,
    canceledStatus,
    enterpriseSignature,
    forms,
  }) => (
    <>
      <span className="fw-semibold">
        Estas entregando a logística un tramite:
      </span>
      <ul>
        <li>Tipo de operación: {transactionType}</li>
        <li>Radicación (Ranking): {ranking}</li>
        <li>Prendado: {pledge}</li>
        <li>Requiere baja: {canceledStatus}</li>
        <li>Tipo de cliente: {enterpriseSignature}</li>
      </ul>
      <span>
        <span className="fw-semibold">Formularios: </span>
        {forms}
      </span>
      <br />
      <br />
      <span className="fw-semibold">
        Recordá separar y archivos los siguientes documentos:
      </span>
      <ul>
        <li>DNI Cliente</li>
        <li>Folio de Seguridad</li>
        <li>Comprobante de Toma</li>
      </ul>
    </>
  );

  const saveComment = () => {
    const dataVehicle = {
      vehicle_id,
      plate_number,
      purchase_file_status,
      comment: fields.comment,
      comment_reason_id: fields.comment_reason_id,
    };

    handleUpdate(dataVehicle)
      .then((response) => {
        setSubmitAction(false);
        if (response) {
          setFields({
            ...fields,
            comment: null,
            comment_reason_id: null,
          });
          setResetCommentForm(+new Date());
          AuditService.getHistory(vehicle_id, "comment_summary").then(
            (response) => {
              setComments(response.data.response);
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    AuditService.getHistory(vehicle_id, "comment_summary").then((response) => {
      setComments(response.data.response);
    });

    if (!finalStatus.includes(purchase_file_status)) {
      // Motivos de los comentarios
      ManagementService.getCommentReasons().then((response) => {
        if (response.data.success) {
          setReasons(
            response.data.response.filter(
              (r) => !r.text.includes("[SUSPENDER]")
            )
          );
        }
      });
    }
    // Tipos de operaciones
    TransactionTypeService.getAll().then((response) => {
      if (response.data.response.count > 0) {
        const result = response.data.response.rows.map((t) => ({
          value: t.transaction_type_id,
          text: t.description,
        }));
        setTransactionTypes(result);
        setTransactionType(
          result?.find((t) => t.value === transaction_type_id)?.text
        );
      }
    });
    //todos los usuarios con el perfil de escribano (filtrados por los ya relacionados)
    // const employeeParams = {
    //   countItems: 1000,
    //   currentPage: 0,
    //   profile: "employee",
    // };
    // UserService.getAllByProfile(employeeParams).then((filterUsers) => {
    //   if (filterUsers.data.success) {
    //     setEmployeeOptions(
    //       filterUsers.data.response.map((r) => ({ value: r.id, text: r.name }))
    //     );
    //   }
    // });

    const params = {
      currentPage: 0,
      countItems: 1000,
      use_for_purchase: 1,
      use_for_sell: null,
    };
    FormService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        const formsData = response.data.response
          .filter(
            (f) =>
              initialFormState.folio_in_progress_forms.findIndex(
                (i) => i.code === f.form_code
              ) === -1
          )
          .map((r) => ({ id: r.form_code, name: r.description }));
        setForms(formsData);
        const allow_remove = response.data.response
          .filter((f) => f.allow_remove)
          .map((r) => r.form_code);
        setAllowRemove(allow_remove);
      }
    });

    CanceledStatusService.getAll({ sortField: "id", sortType: "ASC" })
      .then((response) => {
        if (response.data.count > 0) {
          setCanceledStatus(response.data.response);
          setCancelStatus(
            response.data.response.find((c) => c.value === canceled_status_id)
              ?.text
          );
        }
      })
      .catch((error) => {
        console.error("Failed to fetch canceled statuses:", error);
        // Consider showing a user-friendly error message
      });
    // eslint-disable-next-line
  }, []);

  // Al presionar un botón
  useEffect(() => {
    if (submitAction) {
      const dataVehicle = {
        ...fields,
      };

      dataVehicle.folio_in_progress_forms.forEach((d) => {
        delete d.description;
      });

      handleUpdate(dataVehicle)
        .then((response) => {
          setSubmitAction(false);
          if (response) {
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setDisabledComment(
      !fields.comment?.length ||
        fields.comment_reason_id == null ||
        fields.comment_reason_id === constants.dropdownDefault
    );
  }, [submitAction, fields, handleUpdate, handleClose]);

  useEffect(() => {
    setCustomConfirmText(
      confirmationContent({
        transactionType,
        ranking: issuer_entity.ranking,
        pledge: pledge || "-",
        canceledStatus: cancelStatus,
        enterpriseSignature: enterprise_signature
          ? "Persona Jurídica"
          : "Persona Física",
        forms: fields.folio_in_progress_forms
          .map((f) => f.description)
          .join(", "),
      })
    );
  }, [
    fields.folio_in_progress_forms.length,
    transactionType,
    issuer_entity,
    pledge,
    cancelStatus,
    enterprise_signature,
  ]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // Manejo generico del cambio de un dato complejo de un formulario, como ser un objeto
  const onComplexFieldChange = (primaryKey, value) => {
    const folio_in_progress_forms_tmp = [...fields.folio_in_progress_forms];
    const idx = folio_in_progress_forms_tmp.findIndex(
      (f) => f.code === primaryKey
    );
    folio_in_progress_forms_tmp[idx].has_folio_in_progress = value;
    setFields({
      ...fields,
      folio_in_progress_forms: [...folio_in_progress_forms_tmp],
    });
  };

  // Bloque de cada documento
  const itemBlock = (item) => (
    <div className="row" key={item.checkbox.name}>
      <div className="col">
        <strong className="capitalize">{item.title}</strong>
        {allowRemove.includes(item.checkbox.name) && !item.checkbox.checked ? (
          <Button
            onClick={(e) => onDeleteForm(item, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null}
      </div>
      <div className="col">
        <input
          type="checkbox"
          name={item.checkbox.name}
          checked={item.checkbox.checked}
          onChange={(e) =>
            onComplexFieldChange(item.checkbox.name, e.target.checked)
          }
        />
      </div>
    </div>
  );

  useEffect(() => {
    const perc =
      fields.folio_in_progress_forms.length > 0
        ? (fields.folio_in_progress_forms.filter(
            (f) => f.has_folio_in_progress === true
          ).length *
            100) /
          fields.folio_in_progress_forms.length
        : 0;
    setCompleted(parseInt(perc, 10));
  }, [fields, setCompleted]);

  // Formulario
  const [forms, setForms] = useState([]);
  const [allowRemove, setAllowRemove] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);

  const onSelectForm = (selectedList, selectedItem) => {
    setSelectedForm([selectedItem]);
  };

  const onRemoveForm = () => {
    setSelectedForm([]);
  };

  const onAddForm = () => {
    const params = {
      vehicle_id,
      form_code: selectedForm[0].id,
      opportunity_id: null,
    };
    FormService.addToFolio(params).then((response) => {
      if (response.success) {
        // Se agrega a los formularios listados
        const newArray = [...fields.folio_in_progress_forms];
        newArray.push({
          code: selectedForm[0].id,
          has_folio_in_progress: false,
          description: selectedForm[0].name,
        });
        setFields({ ...fields, folio_in_progress_forms: [...newArray] });
        // Se elimina la opción del selector
        const idx = forms.findIndex((f) => f.id === selectedForm[0].id);
        forms.splice(idx, 1);
        // Limpiamos la opcion seleccionada
        setSelectedForm([]);
      }
    });
  };

  const onDeleteForm = (item, e) => {
    e.preventDefault();
    const {
      title,
      checkbox: { name: code },
    } = item;
    const params = { vehicle_id, form_code: code, opportunity_id: null };
    FormService.removeFromFolio(params).then((response) => {
      if (response.success) {
        // Se elimina el formulario del listado
        const newArray = fields.folio_in_progress_forms.filter(
          (f) => f.code !== code
        );
        setFields({ ...fields, folio_in_progress_forms: [...newArray] });
        // Se agrega la opción del selector
        forms.push({ id: code, name: title });
      }
    });
  };

  const onCheckAll = () => {
    fields.folio_in_progress_forms.forEach((f) =>
      onComplexFieldChange(f.code, true)
    );
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={constants.domain}
              text={plate_number}
              badgeClass={"plate_number"}
              variant={"warning"}
            />
            <BadgeField
              label={"Días en tramite"}
              text={diff_days ?? 0}
              badgeClass={`capitalize plate_number`}
              variant={diffDateFormula(diff_days)}
            />
            <BadgeField
              label={"Registro de Radicación"}
              text={issuer_entity.description}
              badgeClass={`capitalize plate_number`}
              variant={"info"}
            />
            {/* <div className="col">
              <label className="capitalize">
                <strong>Registro de Radicación</strong>
              </label>
              <Multiselect
                name="issuer_entity_id"
                options={issuerEntities()}
                selectedValues={selectedIssuer}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="text"
                placeholder=""
                closeIcon="cancel"
                selectionLimit="1"
                showCheckbox={false}
                singleSelect={false}
                selectedValueDecorator={(v) => v.substr(0, 40)}
              />
            </div> */}
          </div>
          <div className="row">
            {/* <div className="col">
              <label className="capitalize">
                <strong>{constants.lossRequest}</strong>
              </label>
              <div>
                <span>{loss_request}</span>
              </div>
            </div> */}
            {/* <div className="col">
              <label className="capitalize">
                <strong>{constants.personInCharge}</strong>
              </label>
              <div>
                <span>
                  <Dropdown
                    className="form-control"
                    name="person_in_charge_id"
                    placeholder={constants.personInCharge}
                    selected={fields.person_in_charge_id}
                    onSelect={(e) =>
                      setFields({
                        ...fields,
                        person_in_charge_id:
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.value,
                        person_in_charge:
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.options[e.target.selectedIndex].text,
                      })
                    }
                    options={employeeOptions}
                  />
                </span>
              </div>
            </div> */}
            {sdp ? (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.finalPaymentDate}</strong>
                </label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        fields.final_payment_date === null
                          ? null
                          : new Date(fields.final_payment_date)
                      }
                      onChange={(date) =>
                        onSimpleFieldChange("final_payment_date", date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                    />
                  </span>
                </div>
              </div>
            ) : null}
            <BadgeField
              label={constants.transactionType}
              text={transactionType}
              badgeClass={`capitalize plate_number`}
              variant={"secondary"}
            />
            {/* <div className="col">
              <SelectField
                label={constants.transactionType}
                name={"type_id"}
                value={fields.transaction_type_id}
                options={transactionTypes}
                onChange={(e) => {
                  onSimpleFieldChange(
                    "transaction_type_id",
                    e.target.value === "" ? null : e.target.value
                  );
                }}
              />
            </div> */}
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-6">
              <label className="capitalize">
                <strong>Formularios Disponibles</strong>
              </label>
              <Multiselect
                options={forms}
                selectedValues={selectedForm}
                onSelect={onSelectForm}
                onRemove={onRemoveForm}
                displayValue="name"
                placeholder="Formulario"
                closeIcon="cancel"
                selectionLimit="1"
                showCheckbox={false}
                singleSelect={false}
              />
            </div>
            <div className="col-2">
              <label>&nbsp;</label>
              <div>
                <Button
                  className="btn-fill pull-left btn btn-info btn-sm capitalize"
                  onClick={() => onAddForm()}
                  disabled={selectedForm.length === 0}
                >
                  {constants.add}
                </Button>
              </div>
            </div>
            <div className="col-2">
              <label>&nbsp;</label>
              <div>
                <Button
                  className="btn-fill pull-left btn btn-warning btn-sm capitalize"
                  onClick={() => onCheckAll()}
                  disabled={completed === 100}
                >
                  Marcar Todos
                </Button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          {fields.folio_in_progress_forms.map((f) =>
            itemBlock({
              title: f.description,
              checkbox: {
                name: f.code,
                checked: f.has_folio_in_progress,
              },
            })
          )}
          <div className="row">
            <div className="col">
              <ProgressBar
                key={"idx1"}
                bgcolor={"green"}
                completed={completed}
              />
            </div>
          </div>
        </div>
        <Comments
          reasons={reasons}
          comments={purchaseComment(comments)}
          statusList={purchaseStatus}
          onChangeSelect={(e) =>
            onSimpleFieldChange("comment_reason_id", e.target.value)
          }
          onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
          hideReasons={finalStatus.includes(purchase_file_status)}
          reset={resetCommentForm}
        />
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-secundary btn-sm capitalize"
              onClick={() => setSubmitAction(true)}
            >
              {constants.update}
            </Button>
          </div>
          <div>
            <Button
              className="btn-fill pull-right btn btn-sm capitalize"
              variant="secondary"
              disabled={disabledComment}
              onClick={() => saveComment()}
            >
              Comentar Registro
            </Button>
          </div>
          {![
            purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.value,
            purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value,
          ].includes(purchase_file_status) ? (
            <div className="row">
              <Button
                disabled={completed < 100}
                className="btn-fill pull-right btn btn-info btn-sm capitalize"
                onClick={() => {
                  handlePreConfirmationPurchaseModal(
                    "purchase_file_status",
                    purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.value,
                    purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.text,
                    handleChangeStatus
                  );
                }}
              >
                Poner en Espera
              </Button>
            </div>
          ) : null}

          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-danger btn-sm capitalize"
              disabled={disabledComment}
              value={
                purchase_file_status !==
                purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value
                  ? purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value
                  : restore_status
              }
              onClick={(e) => {
                handlePreConfirmationPurchaseModal(
                  "purchase_file_status",
                  e.target.value,
                  purchaseStatus[
                    purchase_file_status !==
                    purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value
                      ? "FOLIO_IN_PROGRESS_OBSERVED"
                      : restore_status
                  ].text,
                  handleChangeStatus
                );
              }}
            >
              {purchase_file_status !==
              purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value
                ? "Observar"
                : "Desobservar"}
            </Button>
          </div>

          {purchase_file_status !==
          purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value ? (
            <div className="row">
              <Button
                disabled={completed < 100}
                className="btn-fill pull-right btn btn-warning btn-sm capitalize"
                onClick={() => {
                  handleConfirmationModal(
                    "",
                    () =>
                      handleChangeStatus(
                        "purchase_file_status",
                        purchaseStatus.PRESENTATION_WAITING.value
                      ),
                    `¿Desea cambiar el estado de la toma a ${purchaseStatus.PRESENTATION_WAITING.text}?`,
                    customConfirmText
                  );
                }}
              >
                {constants.validate}
              </Button>
            </div>
          ) : null}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
