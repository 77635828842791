import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { InputField } from "components/Form";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";

import {
  alias,
  connectedSections,
  multipartSections,
  sectionsNames,
  sections,
  uppercaseValues,
  extraFields,
  extraDefinition,
} from "./mapping";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const PdfViewer = ({ file, data, onPress, onPressDisabled = false }) => {
  const [numPages, setNumPages] = useState(null);
  const [fields, setFields] = useState({});
  let temp = [];

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // Manejo generico del cambio de un dato complejo de un formulario, como ser un objeto
  const onComplexFieldChange = (primaryKey, valueKey, value) => {
    const { [primaryKey]: item } = fields;
    const field = { [primaryKey]: { ...item, [valueKey]: value } };
    setFields({
      ...fields,
      ...field,
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onTextLoadSuccess = (page) => {
    page.getTextContent().then((textContent) => {
      const pageText = textContent.items
        .map((s) => s.str.trim())
        .filter((f) => f.length);
      temp[page.pageNumber - 1] = pageText;
      if (temp.filter((e) => e !== undefined).length === numPages) {
        processContent(temp.flat());
      }
    });
  };

  const keyElement = (value) => {
    const elementAlias = Object.keys(alias).filter((a) => value.includes(a));
    return elementAlias.length ? alias[elementAlias[0]] : value;
  };

  const valueElement = (element, content, index) => {
    let increment = 1;
    let elementValue = content[index + 1];
    if (
      multipartSections[element] &&
      elementValue.length <= multipartSections[element].min
    ) {
      increment++;
      elementValue += content[index + 2];
    }

    // Validación para datos que deben guardarse en mayúsculas
    return {
      elementValue: uppercaseValues.includes(element)
        ? elementValue.toUpperCase()
        : elementValue,
      increment,
    };
  };

  const processContent = (content) => {
    const result = {};
    const sectionsUsed = {};
    const extra = {};
    let section = null;
    let sectionKey = null;
    for (let index = 0; index < content.length; index++) {
      // Contemplar "keys" parciales para los títulos
      const element = keyElement(content[index]);
      // Estoy en una sección
      if (sections[element]) {
        // Si la section tiene una relacion con otra previa, usa el indice
        // de la relacionada y no genera un contador propio
        sectionsUsed[element] = connectedSections[element]
          ? sectionsUsed[connectedSections[element]]
          : (sectionsUsed[element] ?? 0) + 1;

        section = element;
        sectionKey =
          sectionsUsed[element] === 1
            ? sections[section].key
            : `${sections[section].key}-${sectionsUsed[element]}`;
        result[sectionKey] = {};
        continue;
      }
      // Valores extras, no dentro de una sección determinada
      if (extraFields[element]) {
        const { elementValue, increment } = valueElement(
          element,
          content,
          index
        );
        const extraKey = extraFields[element];

        result[extraDefinition[extraKey].section] = {
          ...result[extraDefinition[extraKey].section],
          [extraKey]: {
            label: extraDefinition[extraKey].label,
            value: elementValue,
            name: extraDefinition[extraKey].name,
          },
        };
        index += increment;
        continue;
      }
      // Antes de tener una sección definida
      if (!section) {
        continue;
      }
      // Si existe ese termino dentro de la sección, le agregamos el
      // contenido del siguiente item.
      // Suponemos siempre viene el dato en el siguiente y es unico
      if (sections[section][element]) {
        // En caso de tener el dato separado en mas de un elemento contiguo
        const { elementValue, increment } = valueElement(
          element,
          content,
          index
        );
        result[sectionKey] = {
          ...result[sectionKey],
          [sections[section][element]]: {
            label: element,
            value: elementValue,
            name: sections[section][element],
          },
        };
        index += increment;
        continue;
      }
      // console.log("element", index, element);
    }
    // console.log(result);
    setFields(result);
  };

  useEffect(() => {
    setFields({});
  }, [file]);

  return (
    <div className="content">
      <Container fluid>
        <div style={{ height: "200px", overflow: "auto" }}>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from({ length: numPages }).map((item, index) => (
              <Page
                _className="border p-2"
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onLoadSuccess={onTextLoadSuccess}
                key={index}
              />
            ))}
          </Document>
        </div>
        {Object.entries(fields).length ? (
          <>
            <hr />
            {!!data ? (
              <>
                <h3>
                  Contenido extraido Vehículo #{data.id} - {data.plate_number}
                </h3>
                {data.plate_number !== fields?.vehicle?.plate_number?.value ? (
                  <div className="alert alert-warning" role="alert">
                    <h4 className="alert-heading mt-1">
                      Advertencia: los dominios no coinciden!
                    </h4>
                    <hr />
                    <p>
                      El dominio Original es{" "}
                      <strong>{data?.plate_number}</strong>.
                    </p>
                    <p className="mb-0">
                      El encontrado en el documento es{" "}
                      <strong>{fields?.vehicle?.plate_number?.value}</strong>.
                    </p>
                  </div>
                ) : null}
              </>
            ) : null}
            <Form>
              {Object.entries(fields).map((i) => (
                <div
                  key={`${sectionsNames[i[0].split("-")[0]]}-${Math.random()}`}
                >
                  <h4>{`${sectionsNames[i[0].split("-")[0]]}${
                    !!i[0].split("-")[1] ? ` # ${i[0].split("-")[1]}` : ""
                  }`}</h4>
                  <div className="row">
                    {Object.entries(i[1]).map((field, index) => (
                      <InputField
                        {...field[1]}
                        // onChange={(e) =>
                        //   onComplexFieldChange(
                        //     i[0],
                        //     field[1].name,
                        //     e.target.value
                        //   )
                        // }
                        disabled
                        containerClassName="col-3"
                        key={`${field[1].name}-${index}-${Math.random()}`}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </Form>
            <div className="row mt-3">
              <div className="col">
                {/* <pre>{JSON.stringify(fields, undefined, 2)}</pre> */}
                <Button
                  className={`btn-fill btn btn-${
                    onPressDisabled ? "danger" : "primary"
                  } capitalize`}
                  disabled={onPressDisabled}
                  onClick={() => onPress(fields)}
                  active
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default PdfViewer;
