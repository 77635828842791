import { useState, useEffect } from "react";
import Dropdown from "../../Table/Dropdown";
import { constants } from "../../../variables/constants";

const ReasonBox = ({ reasons, onChangeSelect, reset = 0 }) => {
  const [value, setValue] = useState(constants.dropdownDefault);

  useEffect(() => {
    if (reset) {
      setValue(constants.dropdownDefault);
    }
  }, [reset]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChangeSelect(e);
  };

  return (
    <div className="row">
      <div className="col">
        <label className="capitalize">
          <strong>Motivo del comentario</strong>
        </label>
        <div>
          <Dropdown
            className="form-control"
            name="comment_reason_id"
            placeholder="Motivo del comentario"
            onSelect={handleChange}
            options={reasons}
            selected={value}
          />
        </div>
      </div>
    </div>
  );
};

export default ReasonBox;
