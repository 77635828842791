import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Filters from "../../components/Filters/index";
import Content from "../../components/Modal/Presentation/content";
import Observation from "../../components/Modal/Presentation/observation";
import RejectContent from "../../components/Modal/Reject/content";
import List from "../../components/Table/List";
import ExcelButton from "../../components/ExcelButton";
import Icon from "../../components/Icon";
import ButtonIcon from "../../components/ButtonIcon";
import { useFilters, useGrid, useToast } from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import AuthService from "../../services/auth.service";
import ManagementService from "../../services/management.service";
import PresentationService from "../../services/purchase/presentation.service";
import UploadService from "../../services/upload.service";
import UserService from "../../services/user.service";
import purchaseRequirementsService from "../../services/purchase/requirements.service";
import {
  diffDateBadge,
  parseStatus,
  SDPBadge,
  statusBadge,
} from "../../utils/Badges";
import { downloadAllZip } from "../../utils/File";
import {
  agentProfiles,
  constants,
  fileSections,
  purchaseStatus,
  serviceTypes,
} from "../../variables/constants";
import styles from "../General/styles.module.scss";
import { headSortAgent, headSortOthers } from "./constants/constants_columns";
import {
  filtersPresentationForAgents,
  filtersPresentationForNoAgents,
} from "./constants/constants_filter";

const finalStatus = [purchaseStatus.PRESENTATION_RETIRED.text];

const observedStatus = [
  purchaseStatus.PRESENTATION_PRESENTED.value,
  purchaseStatus.PRESENTATION_OBSERVED_PRESENTED.value,
  purchaseStatus.PRESENTATION_CAT_RECEIVED.value,
];

const dropdownStatus = Object.entries(purchaseStatus)
  .filter((s) => s[1].group === "presentation")
  .map((s) => ({ value: s[1].value, text: s[1].text.substr(15) }))
  .sort((a, b) => (a.order < b.order ? -1 : 1));

const PresentationList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);

  const {
    body,
    handleBody,
    countItems,
    currentPage,
    handleToPage,
    currentSort,
    handleSort,
    handleTotalItems,
    totalItems,
    handleTotalPage,
    totalPage,
    cleanPage,
  } = useGrid({
    model: null,
    field: "vehicle_id",
    type: "ASC",
  });

  const [headSort, setHeadSort] = useState([]);

  const [show, setShow] = useState(false);
  const [showObservation, setShowObservation] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [refresh, setRefresh] = useState(Date.now());

  const [showReject, setShowReject] = useState(false);
  const [dataModalReject, setDataModalReject] = useState(null);

  const user = JSON.parse(localStorage.getItem("user")) || null;

  const profile = user.data.user.profile.name.toLowerCase();

  const addToast = useToast();

  //PAGINATE
  const { filters, onFilterChange } = useFilters(
    !agentProfiles.includes(profile)
      ? filtersPresentationForNoAgents
      : filtersPresentationForAgents(user.data.user.id),
    cleanPage
  );

  const next = {
    link: "/admin/general/list",
    text: "Pantalla General",
  };

  // AGENTES
  const [agentOptions, setAgentOptions] = useState([]);

  useEffect(() => {
    const downloadColumn = (data) => {
      const showIcons =
        currentPermissions &&
        currentPermissions.permissions.hasSelect &&
        !agentProfiles.includes(profile) &&
        data.imagenes > 0;
      return showIcons ? (
        <ButtonIcon
          onClick={(e) => handleDownload(data, e)}
          name="download"
          color="secondary"
          title="Descargar"
        />
      ) : null;
    };

    const sortColumnsAgent = (body) =>
      body.map((e) => ({
        vehicle_id: e.vehicle_id,
        plate_number: SDPBadge(e.plate_number, e.sdp),
        status: e.status,
        transaction_type: e.transaction_type,
        issuer_entity_tentative: e.issuer_entity_tentative_description,
        zone: e.zone,
        waiting_date: e.waiting_date,
        turn_request_date: e.turn_request_date,
        presented_date: e.presented_date,
        turn_take_date: e.turn_take_date,
        retired_date: e.retired_date,
        aging: diffDateBadge(e.aging),
        diff_days: diffDateBadge(e.diff_days),
        loss_request: e.loss_request,
        _status: e.status,
      }));

    const sortColumnsOthers = (body) =>
      body.map((e) => ({
        vehicle_id: e.vehicle_id,
        plate_number: SDPBadge(e.plate_number, e.sdp),
        status: e.status,
        transaction_type: e.transaction_type,
        issuer_entity_tentative: e.issuer_entity_tentative_description,
        zone: e.zone,
        agent: e.agent,
        waiting_date: e.waiting_date,
        turn_request_date: e.turn_request_date,
        presented_date: e.presented_date,
        turn_take_date: e.turn_take_date,
        retired_date: e.retired_date,
        aging: diffDateBadge(e.aging),
        diff_days: diffDateBadge(e.diff_days),
        loss_request: e.loss_request,
        observation: downloadColumn(e),
        _status: e.status,
      }));

    if (profile) {
      const params = {
        page: currentPage,
        limit: countItems,
        filters,
        sortField: currentSort?.field,
        sortType: currentSort?.type,
        sortModel: currentSort?.model,
      };
      PresentationService.getAllPresentation(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        let arraydata = response.data.response;
        arraydata = agentProfiles.includes(profile)
          ? sortColumnsAgent(arraydata)
          : sortColumnsOthers(arraydata);
        handleBody(parseStatus(arraydata));
      });
    }
    //eslint-disable-next-line
  }, [currentPage, filters, currentPermissions, refresh, currentSort, profile]);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions(
      "PRESENTACION REGISTRO"
    );
    setCurrentPermissions(permissions);

    // Lista de agentes
    const params = { currentPage: 0, countItems: 1000, profile: "agent" };
    UserService.getAllByProfile(params).then((response) => {
      if (response.data.count > 0) {
        setAgentOptions(
          response.data.response.map((r) => ({ value: r.id, text: r.name }))
        );
      }
    });

    if (agentProfiles.includes(profile)) {
      setHeadSort(headSortAgent);
      dropdownStatus.splice(
        dropdownStatus.findIndex(
          (p) => p.value === purchaseStatus.PRESENTATION_WAITING.value
        ),
        1
      );
    } else {
      setHeadSort(headSortOthers);
    }

    setRefresh(Date.now());
    const interval = setInterval(() => {
      setRefresh(Date.now());
    }, constants.refreshInterval);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  const handleShowModal = (id, e) => {
    e.preventDefault();
    PresentationService.getPresentationModalDataById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        setShow(true);
      }
    });
  };

  const handleShowModalObservation = (id, e) => {
    e.preventDefault();
    PresentationService.getPresentationModalDataById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        setShowObservation(true);
      }
    });
  };

  const handleShowModalReject = (id, e) => {
    e.preventDefault();
    setDataModalReject({ id });
    setShowReject(true);
  };

  const handleUpdate = (s) => {
    const dataVehicle = {
      ...s,
    };
    const images = {
      vehicle_id: s.vehicle_id,
      plate_number: s.plate_number,
      section: fileSections.presentation,
      images: [...s.images],
    };
    delete dataVehicle.images;
    delete dataVehicle.requirements;

    const arrPromises = [];

    if (s.images.length > 0) {
      const promise_images = new Promise((resolve, reject) => {
        UploadService.save(images).then((response) => {
          if (response.success) {
            resolve("Se guardaron los documentos");
          }
          reject("Fallo el guardado de documentos");
        });
      });
      arrPromises.push(promise_images);
    }

    const promise_vehicle = new Promise((resolve, reject) => {
      ManagementService.edit(dataVehicle).then((response) => {
        if (response.success) {
          resolve("Se actualizaron los datos");
        }
        reject("Fallo la actualización de los datos");
      });
    });

    arrPromises.push(promise_vehicle);

    // Si estoy observando y cargo requisitos
    if (
      s.purchase_file_status === purchaseStatus.PRESENTATION_OBSERVED.value &&
      s.requirements?.length
    ) {
      const promise_requirements = new Promise((resolve, reject) => {
        purchaseRequirementsService
          .insert(s.vehicle_id, s.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Toma - Presentación",
            body: v,
          })
        );
        setRefresh(Date.now());
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Toma - Presentación",
          body: reason,
        });
        setRefresh(Date.now());
      });
  };

  const handleDelete = (params) => {
    ManagementService.edit({
      ...params,
      purchase_file_status: purchaseStatus.REJECTED.value,
    }).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma - Presentación",
          body: "Se rechazó el tramite correctamente",
        });
        setRefresh(Date.now());
      }
    });
  };

  const handleDownload = (data, e) => {
    e.preventDefault();
    const params = {
      countItems: 999,
      currentPage: 0,
      vehicleId: data.vehicle_id,
      plateNumber: data.plate_number,
      section: fileSections.presentation,
    };
    UploadService.download(params).then((response) => {
      if (response.data.success) {
        downloadAllZip(response.data, data.plate_number);
      }
    });
  };

  const handleCloseModal = () => setShow(false);
  const handleCloseModalObservation = () => setShowObservation(false);
  const handleCloseModalReject = () => setShowReject(false);

  const actions = (props) => {
    return (
      <>
        {currentPermissions?.permissions?.hasSelect ? (
          <ButtonIcon
            onClick={(e) => handleShowModal(props.vehicle_id, e)}
            name="file-alt"
            title="Editar"
          />
        ) : null}
        {currentPermissions?.permissions?.hasSelect &&
        observedStatus.includes(props._status) ? (
          <ButtonIcon
            onClick={(e) => handleShowModalObservation(props.vehicle_id, e)}
            name="eye"
            color="warning"
            title="Observar"
          />
        ) : null}
        {currentPermissions?.permissions?.hasDelete &&
        !finalStatus.includes(props.status.props.children) ? (
          <ButtonIcon
            onClick={(e) => handleShowModalReject(props.vehicle_id, e)}
            name="times"
            color="danger"
            size="lg"
            title="Rechazar"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <Container fluid>
        <div className={styles.export}>
          <ExcelButton
            type={`comprasPresentacion${
              agentProfiles.includes(profile) ? "Gestor" : ""
            }`}
            filters={filters}
          />
        </div>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          next={profile && !agentProfiles.includes(profile) ? next : false}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
        <Modal
          content={
            <Content
              data={dataModal}
              profile={profile}
              agentOptions={agentOptions}
              handleUpdate={handleUpdate}
              handleClose={handleCloseModal}
            />
          }
          showModal={show}
          handleShow={handleShowModal}
          handleClose={handleCloseModal}
          headerTitle={statusBadge(dataModal?.response?.status)}
        />

        <Modal
          content={
            <Observation
              data={dataModal}
              profile={profile}
              handleUpdate={handleUpdate}
              handleClose={handleCloseModalObservation}
            />
          }
          showModal={showObservation}
          handleShow={handleShowModalObservation}
          handleClose={handleCloseModalObservation}
          headerTitle={
            <div className="d-flex align-items-center">
              {statusBadge(dataModal?.response?.status)}
              <span className="ms-2 fs-5 fw-semibold">
                Observación - Carga de requisitos
              </span>
            </div>
          }
        />

        <Modal
          content={
            <RejectContent
              data={dataModalReject}
              handleDelete={handleDelete}
              handleClose={handleCloseModalReject}
            />
          }
          showModal={showReject}
          handleShow={handleShowModalReject}
          handleClose={handleCloseModalReject}
        />
      </Container>
    </div>
  );
};

export default PresentationList;
