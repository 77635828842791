import React, { Component, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import authHeader from "../../services/auth-header";
import MenuService from "../../services/menu.service";
import { appName, constants, homePage } from "../../variables/constants";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import styles from "./styles.module.scss";

import logo from "assets/img/logo_kavak_white.svg";

const renderTooltip = (message, props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );
};

const Sidebar = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [menuItems, setMenuItems] = useState([]);
  const [profile, setProfile] = useState(null);
  const [minimizeSidebar, setMinimizeSidebar] = useState(false);

  const { image, location, color, hasImage } = props;

  const sidebarBackground = {
    backgroundImage: `url(${image})`,
  };

  const activeRoute = (routeName) =>
    location?.pathname?.indexOf(routeName) > -1 ? "active" : "";

  const getMenu = async () => await MenuService.get();

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (!!user) {
      getMenu().then((menu) => {
        setMenuItems(menu.data.menu.rows);
        setProfile(user.data.user.profile.name.toLowerCase());
      });
    }
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    if (width <= 991 && minimizeSidebar === true) {
      setMinimizeSidebar(false);
    }
  }, [width, minimizeSidebar]);

  return (
    <div className={minimizeSidebar ? styles.containerSidebar : ""}>
      <div
        id="sidebar"
        className="sidebar"
        data-color={color}
        data-image={image}
      >
        {hasImage ? (
          <div className="sidebar-background" style={sidebarBackground} />
        ) : null}
        {
          <div className="logo">
            <div className="simple-text logo-mini">
              <a
                href={homePage[profile] ?? homePage[constants.adminProfile]}
                style={{ color: "#FFFFFF", textDecoration: "none" }}
              >
                {!minimizeSidebar ? (
                  <>
                    <img src={logo} alt="logo_image" width="100px" /> {appName}
                  </>
                ) : (
                  <img src={logo} alt="logo_image" width="60px" />
                )}
              </a>
            </div>
          </div>
        }
        <div className="sidebar-wrapper">
          <ul className="nav">
            {width > 981 && (
              <li
                onClick={() => setMinimizeSidebar(!minimizeSidebar)}
                className={minimizeSidebar ? styles.icons : ""}
              >
                <NavLink style={{ textDecoration: "none" }} to="#">
                  {!minimizeSidebar ? (
                    <i className="fas fa-chevron-circle-left" />
                  ) : (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 150, hide: 200 }}
                      overlay={(props) => renderTooltip("Achicar", props)}
                    >
                      <i className="fas fa-chevron-circle-right" />
                    </OverlayTrigger>
                  )}
                  {!minimizeSidebar && <p>Achicar</p>}
                </NavLink>
              </li>
            )}
            {width <= 991 ? <AdminNavbarLinks logged={authHeader} /> : null}
            {menuItems
              .sort((a, b) => a.position - b.position)
              .map((menuItem, key) => {
                return (
                  <li key={key} className={minimizeSidebar ? styles.icons : ""}>
                    <NavLink
                      style={{ textDecoration: "none" }}
                      to={menuItem.link}
                    >
                      {minimizeSidebar ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 150, hide: 200 }}
                          overlay={(props) =>
                            renderTooltip(menuItem.title, props)
                          }
                        >
                          <i className={menuItem.icon} />
                        </OverlayTrigger>
                      ) : (
                        <i className={menuItem.icon} />
                      )}
                      {!minimizeSidebar && <p>{menuItem.title}</p>}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
