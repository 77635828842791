import React from "react";
import CustomAccordion from "../Accordion";
import { CommentBox, ObservationBox, ReasonBox } from "./box/";

const Comments = ({
  onChangeObs,
  comments = [],
  reasons = [],
  generic = false,
  onChangeSelect,
  hideReasons = false,
  title = "COMENTARIOS",
  statusList,
  reset = 0,
}) => {
  return (
    <>
      <CustomAccordion title={`${title} - ${comments.length}`}>
        <CommentBox
          comments={comments}
          generic={generic}
          statusList={statusList}
        />
      </CustomAccordion>
      <div>
        {!hideReasons && (
          <>
            {reasons?.length > 0 && (
              <ReasonBox
                reasons={reasons}
                onChangeSelect={onChangeSelect}
                reset={reset}
              />
            )}
            <ObservationBox onChangeObs={onChangeObs} reset={reset} />
          </>
        )}
      </div>
    </>
  );
};

export default Comments;
