import { constants } from "variables/constants";

export const headSortNotary = [
  { text: "Id", field: "id", model: null },
  { text: constants.number, field: "form_number", model: null },
  { text: "Id Vehículo", field: "vehicle_id", model: null },
  { text: "Id Oportunidad", field: "opportunity_id", model: null },
  // { text: "Id Bac", field: "bacs_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: "Tipo", field: "form_type_id", model: null },
  { text: constants.status, field: "current_status", model: null },
  { text: "Fecha Firma", field: "updatedAt", model: "forms_08_signed" },
  { text: "Fecha Entrega", field: "updatedAt", model: "forms_08_delivered" },
  { text: "Fecha Recepción", field: "updatedAt", model: "forms_08_received" },
  { text: "Observación", field: null, model: null },
  // { text: "Fecha Devolución", field: "updatedAt", model: "forms_08_observed" },
  // {
  //   text: "Fecha Reentrega",
  //   field: "updatedAt",
  //   model: "forms_08_observed_delivered",
  // },
  // { text: "Remaining", field: "remaining", model: null },
  {
    text: "Firma Empresa",
    field: "enterprise_signature",
    model: null,
  },
  { text: "Aging", field: null, model: null },
  { text: "Días en Tramite", field: null, model: null },
  { text: "semana toma", field: "createdAt", model: "purchase_file" },
  { text: "Adjuntos", field: null, model: null },
  { text: "", field: null, model: null },
];

export const headSortAdmin = [
  { text: "Id", field: "id", model: null },
  { text: constants.number, field: "form_number", model: null },
  { text: "Id Vehículo", field: "vehicle_id", model: null },
  { text: "Id Oportunidad", field: "opportunity_id", model: null },
  // { text: "Id Bac", field: "bacs_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: "Tipo", field: "form_type_id", model: null },
  { text: constants.status, field: "current_status", model: null },
  { text: "Fecha Firma", field: "updatedAt", model: "forms_08_signed" },
  { text: "Fecha Entrega", field: "updatedAt", model: "forms_08_delivered" },
  { text: "Lugar Firma", field: "notary_office_id", model: null },
  { text: "Fecha Recepción", field: "updatedAt", model: "forms_08_received" },
  { text: "Observación", field: null, model: null },
  {
    text: "Ingreso Legalización",
    field: "legalization_date",
    model: null,
  },
  {
    text: "Retiro Legalización",
    field: "withdrawal_legalization_date",
    model: null,
  },
  {
    text: "Envío de Certificación",
    field: "send_certification_date",
    model: null,
  },
  {
    text: "Recibo Certificación",
    field: "received_certification_date",
    model: null,
  },
  {
    text: "Firma Empresa",
    field: "enterprise_signature",
    model: null,
  },
  { text: "Aging", field: null, model: null },
  { text: "Días en Tramite", field: null, model: null },
  { text: "semana toma", field: "createdAt", model: "purchase_file" },
  { text: "Adjuntos", field: null, model: null },
  { text: "", field: null, model: null },
];

export const headInquiryPurchase = [
  "Id",
  constants.number,
  "Id Vehículo",
  "Tipo",
  constants.status,
  "Fecha Firma",
  "Lugar Firma",
  // "Aging",
  // "Días en Tramite",
  "",
];

export const headInquirySell = [
  "Id",
  constants.number,
  "Id Oportunidad",
  "Tipo",
  constants.status,
  "Fecha Firma",
  "Lugar Firma",
  // "Aging",
  // "Días en Tramite",
  "",
];
