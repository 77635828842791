export const FINANCED_STATUS_NAME = {
  FINANCED: "Financiamiento",
  CASH: "Efectivo",
};

export const status_sells_category_select = [
  {
    text: "Reserva",
    value: "A",
  },
  {
    text: "Venta declarada",
    value: "B",
  },
  {
    text: "Reserva cancelada",
    value: "C",
  },
  {
    text: "Venta facturada",
    value: "D",
  },
  {
    text: "Venta cancelada",
    value: "F",
  },
];

export const providerOptions = [
  { value: "Santander Río", text: "Santander Río" },
  { value: "Supervielle", text: "Supervielle" },
  { value: "Iudú", text: "Iudú" },
  { value: "Mila", text: "Mila" },
  { value: "DeCréditos", text: "DeCréditos" },
  { value: "Uala", text: "Uala" },
  { value: "Galicia", text: "Galicia" },
];

export const financed_status = {
  PENDING_FINANCED: {
    color: "badgePresentationWaiting",
    text: "P - Prenda Pendiente",
    value: 1,
    next: "BANK_ANALYSIS",
    observed: "PENDING_INSCRIPTION",
  },
  PENDING_INSCRIPTION: {
    color: "badgePresentationObserved",
    text: "P - Pendiente de Inscripción",
    value: 2,
    next: "BANK_ANALYSIS",
  },
  BANK_ANALYSIS: {
    color: "badgePresentationDelivered",
    text: "P - En Análisis Banco",
    value: 3,
    next: "PENDING_PHYSICAL_DOCUMENTATION",
    observed: "BANK_OBSERVED",
  },
  BANK_OBSERVED: {
    color: "badgePresentationObserved",
    text: "P - Observado por Banco",
    value: 4,
    next: "PENDING_PHYSICAL_DOCUMENTATION",
  },
  PENDING_PHYSICAL_DOCUMENTATION: {
    color: "badgePresentationPresented",
    text: "P - Pendiente de Documentación Física",
    value: 5,
    next: "BANK_SENT",
  },
  BANK_SENT: {
    color: "badgePresentationReceptionCat",
    text: "P - Enviada al Banco",
    value: 6,
    next: "COMPLETED",
  },
  REGISTRATION_COPY_REQUEST: {
    color: "badgeRejected",
    text: "P - Solicitud Copia Registral",
    value: 7,
    next: null, // vuelve al estado anterior
  },
  COMPLETED: {
    color: "badgeCompleted",
    text: "P - Completado",
    value: 8,
  },
  REJECTED: {
    color: "badgeRejected",
    text: "P - Rechazado",
    value: 9,
  },
};
