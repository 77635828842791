import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { Multiselect } from "multiselect-react-dropdown";

import sellsService from "../../../../services/sells/sells.service";
import sellsPresentationService from "../../../../services/sells/sellsPresentation.service";
import sellRequirementsService from "../../../../services/sells/requirements.service";
import { LogisticInfo } from "./components";
import ConfirmationAlert from "../../../../components/ConfirmationAlert/confirmationAlert";
import { Comments } from "../../../../components/Comments/";
import { sellRequirements } from "../../../../utils/Service";
import {
  sells_status,
  sells_status_select,
} from "../../../../variables/constants";
import { useConfirmationAlert, useToast } from "../../../../hooks/";

const excludeReasons = [18];

const Observation = (props) => {
  const {
    data: {
      response: {
        opportunity_id,
        purchase_issuer_entity,
        status,
        loss_request,
        status_id,
        plate_number,
        comments,
        diff_days,
        diff_days_presentation,
        stock_id,
        suspended_days,
      },
    },
    profile,
    handleUpdate,
    handleClose,
    refresh,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    stock_id,
    plate_number,
    status_id: sells_status.PRESENTATION_OBSERVED.value,
    comment_reason_id: null,
    comment: null,
  };

  const constantData = {
    diff_days,
    diff_days_presentation,
    current_location: purchase_issuer_entity,
    suspended_days,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);

  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const submit = () => {
    const dataOpportunity = {
      ...fields,
      opportunity_id,
    };

    const arrPromises = [];

    const promise_opportunity = new Promise((resolve, reject) => {
      sellsPresentationService
        .update(opportunity_id, dataOpportunity)
        .then((response) => {
          if (response.status === 200) {
            resolve("Se actualizaron los datos");
          } else {
            reject("Fallo la actualización de los datos");
          }
        });
    });
    arrPromises.push(promise_opportunity);

    // Estoy observando y cargo requisitos
    const promise_requirements = new Promise((resolve, reject) => {
      sellRequirementsService
        .insert(opportunity_id, fields.requirements)
        .then((response) => {
          if (response.success) {
            resolve("Se crearon los requisitos");
          } else {
            reject("Fallo la creación de los requisitos");
          }
        });
    });

    arrPromises.push(promise_requirements);

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Ventas - Presentación",
            body: v,
          })
        );
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Ventas - Presentación",
          body: reason,
        });
        refresh();
      });
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const getReasons = async () => {
    try {
      const response = await sellsService.getAllReasons();
      if (response.status === 200) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <LogisticInfo
            fields={fields}
            onSimpleFieldChange={onSimpleFieldChange}
            profile={profile}
            constantData={constantData}
          />

          <Comments
            reasons={reasons}
            comments={comments}
            statusList={sells_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
          />
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>Requisitos pendientes</strong>
              </label>
              <Multiselect
                options={sellRequirements()}
                onSelect={(selectedItem) => {
                  onSimpleFieldChange("requirements", selectedItem);
                }}
                onRemove={(selectedItem) => {
                  onSimpleFieldChange("requirements", selectedItem);
                }}
                displayValue="text"
                placeholder="Requisitos"
                closeIcon="cancel"
                selectionLimit="-1"
                showCheckbox={true}
                singleSelect={false}
              />
            </div>
          </div>
        </div>
        <br />

        <div className="modal-footer">
          <div className="w-100 d-flex justify-content-end">
            <Button
              className="btn-fill btn btn-danger btn-sm capitalize"
              onClick={() =>
                handleConfirmationModal(
                  sells_status.PRESENTATION_OBSERVED.text,
                  () => submit()
                )
              }
              disabled={
                !fields.comment?.length ||
                !fields.comment_reason_id ||
                !fields.requirements?.length ||
                excludeReasons.includes(parseInt(fields.comment_reason_id))
              }
            >
              {sells_status.PRESENTATION_OBSERVED.text.substr(15)}
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Observation;
