import { useState, useEffect } from "react";

const ObservationBox = ({ onChangeObs, reset = 0 }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (reset) {
      setValue("");
    }
  }, [reset]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChangeObs(e);
  };

  return (
    <div className="row">
      <div className="col">
        <label className="capitalize">
          <strong>Observación</strong>
        </label>
        <div>
          <textarea
            className="form-control"
            rows={3}
            name="observation"
            placeholder="Ingrese un comentario"
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ObservationBox;
