import { badgeGeneric } from "utils/Badges";
import CustomAccordion from "../../components/Accordion/index";

const SeeForms = ({ forms }) => {
  return (
    <div>
      <CustomAccordion title={"Formularios"} defaultOpen={true}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {forms &&
            forms.map((form) => {
              return (
                <div
                  key={`form_id-${
                    form?.forms?.form_code ?? `unknown-${+new Date()}`
                  }`}
                >
                  {badgeGeneric("warning", form.forms.description)}
                </div>
              );
            })}
        </div>
      </CustomAccordion>
    </div>
  );
};

export default SeeForms;
