import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import Dropdown from "../../../components/Table/Dropdown";
import { useConfirmationAlert, useToast } from "../../../hooks";
import AuditService from "../../../services/audit.service";
import AuthService from "../../../services/auth.service";
import ManagementService from "../../../services/management.service";
import NotaryService from "../../../services/notary.service";
import PurchaseReceptionService from "../../../services/purchaseReception.service";
import StatusForm08Service from "../../../services/form08/statusForm08.service";
import UploadService from "../../../services/upload.service";
import { badgeGeneric, status08Badge } from "../../../utils/Badges";
import { processFiles } from "../../../utils/File";
import { issuerEntitiesAll } from "../../../utils/Service";
import {
  constants,
  fileSections,
  formType,
  form_type,
  purchaseStatus,
  status08,
  yesNoOptions,
} from "../../../variables/constants";
import { DatePickerField, InputField, SelectField } from "../../Form/index";
import NotaryCollegeService from "../../../services/purchase/notaryCollege.service";

// const resetRemaining = [status08.SIGNED.value, status08.OBSERVED.value];

const finalStatus = [status08.COMPLETED.value, status08.CANCELED.value];

const disabledUpdate = [
  purchaseStatus.COMPLETED.value,
  purchaseStatus.REJECTED.value,
  purchaseStatus.DISMISSED_KAVAK.value,
];

const allowUpdateIssuerEntity = [
  purchaseStatus.PENDING_VALIDATION.value,
  purchaseStatus.VALIDATION_EXCEPTION.value,
  purchaseStatus.SIGNING_FORM_08.value,
  purchaseStatus.FOLIO_IN_PROGRESS.value,
  purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.value,
];

// Campos opcionales para avanzar en el flujo
const avoidFieldsValidation = [
  "cancel_reason_id",
  "observation",
  "issuer_entity_tentative_id",
  "document_count",
  "notary_college_id",
  "withdrawal_legalization_date",
  "legalization_date",
  "received_certification_date",
  "send_certification_date",
];

const hideReceptionByType = Object.entries(form_type)
  .filter((f) => f[1].hideReception)
  .map((t) => t[1].value);
// Motivos de comentarios que no se pueden observar
const excludeReasons = [6, 7];

const Content = (props) => {
  const {
    data: {
      response: {
        id,
        vehicle_id,
        form_number,
        plate_number,
        current_status,
        notary_office,
        form_type,
        signature_count,
        signature_count_client,
        sworn_statement,
        zero_two,
        enterprise_signature,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        ranking,
        purchase_file_status,
        updatedAt,
        document_count,
        notary_college_id,
        // remaining,
        sworn_blue_status,
        withdrawal_legalization_date,
        legalization_date,
        received_certification_date,
        send_certification_date,
      },
    },
    handleClose,
    setRefresh,
  } = props;

  // Siguiente estado
  const nextStatus = status08[status08[current_status].next];

  const initialFormState = {
    id,
    plate_number,
    form_code: "08",
    current_status,
    form_type_id: form_type?.id,
    signature_count,
    signature_count_client,
    sworn_statement,
    zero_two,
    enterprise_signature,
    images: [],
    cancel_reason_id: null,
    observation: null,
    issuer_entity_tentative_id,
    sworn_blue_status: sworn_blue_status,
    document_count,
    notary_college_id,
    withdrawal_legalization_date,
    legalization_date,
    send_certification_date,
    received_certification_date,
  };

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [allowProfile, setAllowProfile] = useState(false);
  const [profile, setProfile] = useState(null);
  const [plateNumberAvailable, setPlateNumberAvailable] = useState("");
  const [disabledPlateNumber, setDisabledPlateNumber] = useState(false);
  const [hasReceptionDate, setHasReceptionDate] = useState(false);
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [notaryOffices, setNotaryOffices] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [notaryCollege, setNotaryColleges] = useState([]);

  const statusButtonRef = useRef(false);
  const statusRef = useRef(false);
  const issuerEntityUpdateRef = useRef(false);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();
  const addToast = useToast();

  const onSelect = (selectedList, selectedItem) => {
    setSelectedIssuer([selectedItem]);
    onSimpleFieldChange("issuer_entity_tentative_id", selectedItem.value);
  };

  // Eliminación del emisor
  const onRemove = () => {
    onSimpleFieldChange("issuer_entity_tentative_id", null);
  };

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("ESTADOS_08");
    const user = JSON.parse(localStorage.getItem("user")) || null;
    // Verificación del perfil para la accion principal
    setAllowProfile(
      user.data.user.profile.name.toLowerCase() ===
        status08[current_status].profile.toLowerCase() ||
        (user.data.user.profile.name.toLowerCase() !==
          constants.notaryProfile &&
          permissions.permissions.hasDelete === 1)
    );

    setProfile(user.data.user.profile.name.toLowerCase());
    // El escribano puede editarla cuando esta en espera o cuando no esta el vehicle_id (!= null) para evitar errores de consistencia de datos
    setDisabledPlateNumber(
      (user.data.user.profile.name.toLowerCase() === constants.notaryProfile &&
        current_status !== status08.WAITING.value) ||
        vehicle_id !== null
    );

    if (issuer_entity_tentative_id != null) {
      setSelectedIssuer([
        {
          value: issuer_entity_tentative_id,
          text: issuer_entity_tentative,
        },
      ]);
    }

    // Verifica si se cargo la fecha de toma
    if (plate_number) {
      PurchaseReceptionService.findOneByPlateNumber(plate_number).then(
        (responseP) => {
          if (responseP.data.success) {
            setHasReceptionDate(
              moment(responseP.data.data.is_recived_date).format("DD/MM/YYYY")
            );
          } else if (vehicle_id) {
            ManagementService.getById(vehicle_id).then((responseM) => {
              if (responseM.data.success) {
                setHasReceptionDate(
                  responseM.data.response.recived_date ?? false
                );
              }
            });
          } else {
            setHasReceptionDate(false);
          }
        }
      );
      // Busqueda de comentarios historicos
      AuditService.getFormComments(id, "comment_summary").then((response) => {
        setComments(response.data.response);
      });
      // Motivos de los comentarios
      StatusForm08Service.getCancelReasons().then((response) => {
        if (response.data.success) {
          setReasons(response.data.response);
        }
      });
    }
    if (current_status === status08.WAITING.value) {
      NotaryService.getAll({ sortField: "name", sortType: "ASC" }).then(
        (response) => {
          if (response.data.count > 0) {
            setNotaryOffices(response.data.response);
          }
        }
      );
    }

    //here we are
    statusButtonRef.current = finalStatus.includes(current_status);

    statusRef.current = !finalStatus.includes(current_status);

    if (purchase_file_status != null) {
      statusRef.current =
        !disabledUpdate.includes(purchase_file_status) &&
        !finalStatus.includes(current_status);

      statusButtonRef.current =
        disabledUpdate.includes(purchase_file_status) &&
        finalStatus.includes(current_status);

      issuerEntityUpdateRef.current =
        statusRef.current &&
        allowUpdateIssuerEntity.includes(purchase_file_status);
    }

    // eslint-disable-next-line
    NotaryCollegeService.getAllNotaryCollege().then((response) => {
      if (response.data.response.length > 0) {
        setNotaryColleges(response.data.response);
      }
    });

    const notary_office_id =
      user.data.user.notary_office_users[0]?.notary_office_id;
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Validacion de campos
    const simplesFields =
      Object.entries(fields).filter(
        (f) =>
          !avoidFieldsValidation.includes(f[0]) &&
          (f[1] === null ||
            f[1] === "null" ||
            f[1] === "" ||
            f[1] === undefined)
      ).length === 0;
    // Validaciones adicionales
    // Proximo estado Observado y sin motivo o comentario
    const allowObserved =
      !finalStatus.includes(current_status) &&
      nextStatus.value === status08.OBSERVED.value &&
      fields.cancel_reason_id !== null &&
      fields.observation?.length > 0 &&
      !excludeReasons.includes(parseInt(fields.cancel_reason_id));
    // Criterio de legalizado para pasar a entregado
    // const blockDelivery =
    //   nextStatus && nextStatus.value === status08.DELIVERED.value;

    const blockNotary =
      profile === constants.notaryProfile &&
      nextStatus &&
      nextStatus.value === status08.RECEIVED.value &&
      fields.sworn_blue_status;

    const blockAgentExt =
      nextStatus &&
      nextStatus.value === status08.OBSERVED.value &&
      constants.agentExternalProfile === profile;

    setAllowSubmit(
      simplesFields &&
        allowProfile &&
        !allowObserved &&
        // !blockDelivery &&
        !blockNotary &&
        !blockAgentExt
    );
    if (submitAction) {
      setSubmitAction(false);

      let dataForm = {
        ...fields,
        sworn_blue_status: fields.sworn_blue_status === "si" ? 1 : 0,
      };

      const arrPromises = [];

      if (fields.images.length > 0) {
        const images = {
          vehicle_id,
          plate_number: fields.plate_number,
          section: fileSections.form08,
          images: [...fields.images],
        };

        delete dataForm.images;

        const promise_images = new Promise((resolve, reject) => {
          if (fields.images.length > 0) {
            UploadService.save(images).then((response) => {
              if (response.success) {
                resolve("Se guardaron los documentos");
              }
              reject("Fallo el guardado de documentos");
            });
          }
        });

        arrPromises.push(promise_images);
      }

      const promise_form = new Promise((resolve, reject) => {
        StatusForm08Service.edit(dataForm).then((response) => {
          if (response.success) {
            resolve("Se actualizaron los datos");
          }
          reject("Fallo la actualización de los datos");
        });
      });

      arrPromises.push(promise_form);

      Promise.all(arrPromises)
        .then((values) => {
          values.map((v) =>
            addToast({
              variant: "success",
              header: "Formularios 08",
              body: v,
            })
          );
          setRefresh(Date.now());
          handleClose();
        })
        .catch((reason) => {
          addToast({
            variant: "danger",
            header: "Formularios 08",
            body: reason,
          });
        });
    } else {
      if (
        fields.plate_number?.length > 5 &&
        plate_number !== fields.plate_number
      ) {
        const params = {
          page: 0,
          limit: 10,
          filters: {
            plateNumber: {
              name: "plateNumber",
              placeholder: "Dominio",
              size: 2,
              type: "text",
              value: fields.plate_number,
            },
          },
          sortField: null,
          sortType: null,
          sortModel: null,
        };
        StatusForm08Service.getAll(params).then((response) => {
          setPlateNumberAvailable(
            response.data.totalItems === 0
              ? badgeGeneric("success", "disponible")
              : badgeGeneric("warning", "utilizada - verificar")
          );
        });
      } else {
        setPlateNumberAvailable("");
      }
    }
  }, [
    submitAction,
    fields,
    allowProfile,
    current_status,
    nextStatus,
    plate_number,
    vehicle_id,
    // remaining,
    handleClose,
    setRefresh,
    profile,
  ]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleFiles = async (e) => {
    const newImages = await processFiles(e.target.files);
    setFields({
      ...fields,
      images: [...newImages],
    });
  };

  const handleReceptionButton = async (e) => {
    e.preventDefault();
    // si ya esta relacionado a la toma, debo actualizar la toma y no guardar el dato temporalmente
    const response = vehicle_id
      ? await ManagementService.edit({
          vehicle_id,
          is_recived: true,
          is_recived_date: new Date(),
        })
      : await PurchaseReceptionService.add({
          plate_number,
          is_recived_date: new Date(),
        });

    if (response.success) {
      setHasReceptionDate(moment(new Date()).format("DD/MM/YYYY"));
      addToast({
        variant: "success",
        header: "Formularios 08",
        body: "Se guardó con exito la fecha de recepción de la toma",
      });
    } else {
      addToast({
        variant: "danger",
        header: "Formularios 08",
        body: "Ocurrió un error en el guardado de la fecha de recepción de la toma",
      });
    }
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  const receptionComponent = (hasReceptionDate, plate_number) => {
    if (hasReceptionDate) {
      return (
        <div>
          <span>Recepción Toma: {hasReceptionDate}</span>
        </div>
      );
    }
    return profile !== constants.notaryProfile &&
      !hasReceptionDate &&
      plate_number ? (
      <div>
        <Button
          className="btn-fill btn btn-info btn-sm"
          onClick={(e) => {
            handleReceptionButton(e);
          }}
        >
          Informar Recepción Toma
        </Button>
      </div>
    ) : (
      <div>&nbsp;</div>
    );
  };

  const notaryOfficeComponent = () => {
    if (profile === constants.notaryProfile) {
      return null;
    }
    return (
      <div className="col">
        <label className="capitalize">
          <strong>{constants.notaryOffice}</strong>
        </label>
        <div>
          {current_status !== status08.WAITING.value ? (
            <span>{notary_office?.name}</span>
          ) : (
            <Select
              className="form-control"
              name="notary_office_id"
              onChange={(e) =>
                onSimpleFieldChange(
                  "notary_office_id",
                  parseInt(e.target.value, 10)
                )
              }
              value={notary_office?.id}
            >
              <option value="null">Seleccione</option>
              {notaryOffices.map((prop, key) => {
                return (
                  <option key={key} value={prop.id}>
                    {prop.name}
                  </option>
                );
              })}
            </Select>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.number}</strong>
              </label>
              <div>
                <span>{form_number}</span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.status}</strong>
              </label>
              <div>
                <span>{status08Badge(current_status)}</span>
              </div>
            </div>
            {notaryOfficeComponent()}
          </div>
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.domain}</strong>
                <span>{plateNumberAvailable}</span>
              </label>
              <div>
                <span>
                  <Input
                    type="text"
                    className="form-control"
                    name="plate_number"
                    placeholder={constants.domain}
                    autoComplete="false"
                    value={fields.plate_number}
                    onChange={(e) =>
                      onSimpleFieldChange("plate_number", e.target.value)
                    }
                    disabled={
                      disabledPlateNumber &&
                      !disabledUpdate.includes(purchase_file_status)
                    }
                    style={{ textTransform: "uppercase" }}
                  />
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.transactionType}</strong>
              </label>
              <div>
                {current_status === status08.WAITING.value ? (
                  <Select
                    className="form-control"
                    name="form_type_id"
                    onChange={(e) =>
                      onSimpleFieldChange("form_type_id", e.target.value)
                    }
                    value={fields.form_type_id}
                  >
                    <option value="null">Seleccione</option>
                    {formType.map((f) => (
                      <option
                        key={f.text}
                        value={f.value}
                        className="capitalize"
                      >
                        {f.text}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <span>{form_type?.description}</span>
                )}
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.enterpriseSignature}</strong>
              </label>
              <div>
                {statusRef.current ? (
                  <Select
                    className="form-control"
                    name="enterprise_signature"
                    onChange={(e) =>
                      onSimpleFieldChange(
                        "enterprise_signature",
                        e.target.value
                      )
                    }
                    value={fields.enterprise_signature}
                  >
                    <option value="null">Seleccione</option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                  </Select>
                ) : (
                  <span>
                    {enterprise_signature === null
                      ? "-"
                      : enterprise_signature
                      ? "Si"
                      : "No"}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{`${constants.signatureCount} Check`}</strong>
              </label>
              <div>
                <span>
                  {statusRef.current ? (
                    <Input
                      type="number"
                      className="form-control"
                      name="signature_count"
                      placeholder={`${constants.signatureCount} Check`}
                      autoComplete="false"
                      value={fields.signature_count}
                      onChange={(e) =>
                        onSimpleFieldChange("signature_count", e.target.value)
                      }
                    />
                  ) : (
                    fields.signature_count ?? "-"
                  )}
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{`${constants.signatureCount} Cliente`}</strong>
              </label>
              <div>
                <span>
                  {statusRef.current ? (
                    <Input
                      type="number"
                      className="form-control"
                      name="signature_count_client"
                      placeholder={`${constants.signatureCount} Cliente`}
                      autoComplete="false"
                      value={fields.signature_count_client}
                      onChange={(e) =>
                        onSimpleFieldChange(
                          "signature_count_client",
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    fields.signature_count_client ?? "-"
                  )}
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.swornStatement}</strong>
              </label>
              <div>
                <span>
                  {statusRef.current ? (
                    <Input
                      type="number"
                      className="form-control"
                      name="sworn_statement"
                      placeholder={constants.swornStatement}
                      autoComplete="false"
                      value={fields.sworn_statement}
                      onChange={(e) =>
                        onSimpleFieldChange("sworn_statement", e.target.value)
                      }
                    />
                  ) : (
                    fields.sworn_statement ?? "-"
                  )}
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.zeroTwo}</strong>
              </label>
              <div>
                <span>
                  {statusRef.current ? (
                    <Input
                      type="number"
                      className="form-control"
                      name="zero_two"
                      placeholder={constants.zeroTwo}
                      autoComplete="false"
                      value={fields.zero_two}
                      onChange={(e) =>
                        onSimpleFieldChange("zero_two", e.target.value)
                      }
                    />
                  ) : (
                    fields.zero_two ?? "-"
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <label className="capitalize">
                <strong>fecha última actualización</strong>
              </label>
              <div>
                <span>{updatedAt}</span>
              </div>
            </div>
            <div className="col-4">
              <label className="capitalize">
                <strong>Registro donde se Presenta</strong>
              </label>
              {issuerEntityUpdateRef.current ? (
                <Multiselect
                  name="issuer_entity_id"
                  options={issuerEntitiesAll()}
                  selectedValues={selectedIssuer}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="text"
                  placeholder=""
                  closeIcon="cancel"
                  selectionLimit="1"
                  showCheckbox={false}
                  singleSelect={false}
                  selectedValueDecorator={(v) => v.substr(0, 40)}
                />
              ) : (
                <div>{issuer_entity_tentative ?? ""}</div>
              )}
            </div>
            <div className="col-4">
              <label className="capitalize">
                <strong>Ranking</strong>
              </label>
              <div>
                <span>{ranking}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <SelectField
              label={"DDJJ Cédula Azul"}
              name={"sworn_blue_status"}
              onChange={(e) =>
                onSimpleFieldChange(
                  "sworn_blue_status",
                  !!e.target.value ? e.target.value : null
                )
              }
              value={fields.sworn_blue_status}
              options={yesNoOptions}
            />
          </div>
          {![
            status08.WAITING.value,
            status08.DELIVERED.value,
            status08.OBSERVED_DELIVERED.value,
            status08.ARCHIVED.value,
            status08.CANCELED.value,
            status08.REMOVED.value,
          ].includes(current_status) ? (
            <>
              <div className="row">
                <SelectField
                  label={"Colegio de escribanos"}
                  name={"notary_college_id"}
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "notary_college_id",
                      !!e.target.value ? e.target.value : null
                    )
                  }
                  value={fields.notary_college_id}
                  options={notaryCollege}
                />
                <DatePickerField
                  label={"Fecha de ingreso legalización"}
                  selected={
                    fields.legalization_date
                      ? new Date(fields.legalization_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("legalization_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <DatePickerField
                  label={"Fecha de retiro legalización"}
                  selected={
                    fields.withdrawal_legalization_date
                      ? new Date(fields.withdrawal_legalization_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("withdrawal_legalization_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <InputField
                  label={"Cantidad de documentos"}
                  name={"document_count"}
                  onChange={(e) =>
                    onSimpleFieldChange("document_count", e.target.value)
                  }
                  value={fields.document_count}
                  type="number"
                />
              </div>
              <div className="row">
                <DatePickerField
                  label={"Fecha de envio de certificación"}
                  selected={
                    fields.send_certification_date
                      ? new Date(fields.send_certification_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("send_certification_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <DatePickerField
                  label={"Fecha de recibo de certificación"}
                  selected={
                    fields.received_certification_date
                      ? new Date(fields.received_certification_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("received_certification_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </>
          ) : null}
          <hr />
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.history}</strong>
              </label>
            </div>
          </div>
          {comments?.length === 0 ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.noComments}</strong>
                </label>
              </div>
            </div>
          ) : (
            comments.map((c) => (
              <>
                <div className="row">
                  <div className="col-sm-3">{c.updatedAt}</div>
                  <div className="col-sm-3">{c.updatedUser}</div>
                  <div className="col-sm-3">{c.cancel_reason ?? "-"}</div>
                  <div className="col">{c.observation}</div>
                </div>
                <hr style={{ marginTop: "0.2rem", marginBottom: "0.2rem" }} />
              </>
            ))
          )}
          {plate_number &&
          current_status !== status08.WAITING.value &&
          statusRef.current ? (
            <>
              <div className="row">
                <div className="col">
                  <label className="capitalize">
                    <strong>Motivo del comentario</strong>
                  </label>
                  <div>
                    <Dropdown
                      className="form-control"
                      name="cancel_reason_id"
                      placeholder="Motivo del comentario"
                      onSelect={(e) =>
                        onSimpleFieldChange(
                          "cancel_reason_id",
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.value
                        )
                      }
                      options={reasons}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="capitalize">
                    <strong>{constants.observation}</strong>
                  </label>
                  <div>
                    <Textarea
                      className="form-control"
                      rows={3}
                      name="observation"
                      placeholder="Ingrese un comentario"
                      onChange={(e) =>
                        onSimpleFieldChange("observation", e.target.value)
                      }
                      // disabled={profile === constants.notaryProfile}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {profile !== constants.notaryProfile &&
          plate_number &&
          current_status !== status08.WAITING.value &&
          statusRef.current ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.files}</strong>
                </label>
                <div>
                  <span>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      name="images"
                      encType="multipart/form-data"
                      multiple={true}
                      onChange={handleFiles}
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <br />
        <div className="modal-footer">
          {statusButtonRef.current ? null : (
            <div>
              <Button
                className="btn-fill pull-right btn btn-primary btn-sm"
                onClick={() => {
                  setSubmitAction(true);
                }}
              >
                {constants.update}
              </Button>
            </div>
          )}
          {!hideReceptionByType.includes(form_type?.id)
            ? receptionComponent(hasReceptionDate, plate_number)
            : null}
          {statusButtonRef.current ? null : (
            <div className="row">
              <Button
                className="btn-fill pull-right btn btn-warning btn-sm"
                onClick={(e) => {
                  handlePreConfirmationPurchaseModal(
                    "current_status",
                    nextStatus.value,
                    nextStatus.text,
                    handleChangeStatus
                  );
                }}
                disabled={!allowSubmit}
              >
                {nextStatus && nextStatus.text}
              </Button>
            </div>
          )}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
